/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Fragment, useEffect, useState } from 'react';

import { createUseStyles } from 'react-jss';
import screenfull from 'screenfull';

import DetailPage from './components/DetailPage';
import HomePage from './components/HomePage';
import ProductList from './components/ProductList';
import LivingRoom from './components/LivingRoom';
import isMobile from 'is-mobile';

const useStyles = createUseStyles({
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    // background: 'rgb(243,234,224)',
    // background:
    //   'linear-gradient(180deg, rgba(243,234,224,1) 10%, rgba(255,255,255,0) 100%)',
  },
  label: {
    fontFamily: 'TTCommonsTR',
    fontWeight: 300,
    color: '#44464b',
    fontSize: '21px',
    lineHeight: 1.2,
    letterSpacing: '1pt',
  },
});

function App() {
  const classes = useStyles();
  const [page, setPage] = useState('home');
  const [active, setActive] = useState('');
  const [isFullscreenState, setFullScreenState] = useState(false);
  useEffect(() => {
    if (active) {
      console.log('active:: ', active);
      setPage('detailPage');
    }
  }, [active]);

  const [hearts, setHearts] = useState([]);

  const base = `/assets/${active}_out/${active}`;
  const src = `${base}.glb`;
  const poster = `${base}.png`;
  const iosSrc = `${base}.usdz`;

  const descriptions = {
    N101: {
      title: 'N101 sofa',
      subTitle: '3 seater',
      description: 'Simply chic with a hint of vintage. The retro style of the N101 sofa is reimagined by giving it a velvet jacket. Its effortless elegance elevates any setting to “ritzy” in an instant.',
      oldPrice: '€779.00',
      newPrice: '€13.00 / month',
    },
    CIRCLETABLE: {
      title: 'Oak circle dining table',
      description: 'Our Circle dining table is all about inspiring your family to spend a little more time at the table, its solid legs intertwining in perfect balance.',
      price: 'Our Circle dining table is all about inspiring your family to spend a little more time at the table, its solid legs intertwining in perfect balance.',
      oldPrice: '1299.00',
      newPrice: '€21.50 / month',
    },
  };

  // screenfull.on('change', () => {
  //   console.log('changed', screenfull.isFullscreen);
  //   // eslint-disable-next-line no-restricted-globals
  //   // screen.orientation.lock('landscape');

  //   setFullScreenState(screenfull.isFullscreen);
  // });
  console.log('page', page, isFullscreenState);
  // useEffect(() => {
  //   console.log('is', isMobile());
  //   if (isMobile()) {
  //     if (page === 'home' && isFullscreenState) {
  //       screenfull.exit();
  //     }
  //     if (page !== 'home' && !isFullscreenState) {
  //       screenfull.request();
  //     }
  //   }
  // }, [isFullscreenState, page]);
  return (
    <Fragment>
      {page === 'home' && (
        <HomePage
          setPage={() => {
            setPage('productList');
          }}
        />
      )}
      {page === 'detailPage' && (
        <DetailPage
          poster={poster}
          title={descriptions[active].title}
          subTitle={descriptions[active].subTitle}
          description={descriptions[active].description}
          oldPrice={descriptions[active].oldPrice}
          newPrice={descriptions[active].newPrice}
          setPage={setPage}
          src={src}
          iosSrc={iosSrc}
          heart={hearts.includes(descriptions[active].title)}
          toggleHeart={() => {
            const value = descriptions[active].title;
            setHearts([
              ...(hearts.includes(value)
                ? hearts.filter((heart) => heart !== value)
                : [...hearts, value]),
            ]);
          }}
        />
      )}
      {page === 'productList' && <ProductList setPage={setPage} />}
      {page === 'livingRoom' && (
        <LivingRoom setPage={setPage} setActive={setActive} />
      )}
    </Fragment>
  );
}

export default App;
