import { Fragment } from 'react';
import { createUseStyles } from 'react-jss';
import TopBar from './TopBar';

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  title: {
    fontSize: '21px',
    fontFamily: 'TTCommonsTRBold',
    alignSelf: 'baseline',
    padding: '18px 0',
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#F5ECE8',
  },
  productContainer: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: '25% 25% 25% 25%',
    gridAutoFlow: 'row',
    alignItems: 'center',
    margin: '5px 5% 40px',
    height: 'calc(100% - 132px)',
    overflowY: 'scroll',
    padding: '10px 0',
  },
  imageContainer: {
    // margin: '5px',
    width: '95%',
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '5%',
    },
    '& div': {
      position: 'absolute',
      bottom: 5,
      left: '50%',
      transform: 'translate(-50%, -20%)',
      color: 'white',
      fontSize: '14px',
      fontFamily: 'TTCommonsTRBold',
    },
  },
});

const ProductList = ({ poster, setPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar onClick={() => setPage('home')} />
      <div className={classes.title}>Products</div>
      <div className={classes.productContainer}>
        <div
          style={{ gridColumn: '1', alignSelf: 'start', height: '95%' }}
          className={classes.imageContainer}
          onClick={() => setPage('livingRoom')}
        >
          <div>Dining Room</div>
          <img src="/assets/products/dining-room.png" alt="dining room" />
        </div>
        <div
          style={{
            gridRow: '1 / span 2',
            height: '95%',
            alignSelf: 'start',
            gridColumn: '2',
          }}
          className={classes.imageContainer}
          onClick={() => setPage('livingRoom')}
        >
          <div>Living Room</div>
          <img src="/assets/products/living-room.png" alt="living room" />
        </div>
        <div
          style={{ gridRowStart: 'span 2', height: '95%', gridColumn: '1' }}
          className={classes.imageContainer}
          onClick={() => setPage('livingRoom')}
        >
          <div>Office</div>
          <img src="/assets/products/office.png" alt="office" />
        </div>
        <div
          style={{
            gridRow: '3 / span 2',
            alignSelf: 'end',
            height: '95%',
            gridColumn: '2',
          }}
          className={classes.imageContainer}
          onClick={() => setPage('livingRoom')}
        >
          <div>Bedroom</div>
          <img src="/assets/products/bedroom.png" alt="bedroom" />
        </div>
        <div
          style={{ gridColumn: '1', alignSelf: 'end', height: '95%' }}
          className={classes.imageContainer}
          onClick={() => setPage('livingRoom')}
        >
          <div>Accessoiries</div>
          <img src="/assets/products/accessoiries.png" alt="accessoiries" />
        </div>
      </div>
      {/* <BottomBar /> */}
    </div>
  );
};

export default ProductList;
