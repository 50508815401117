import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles({
  root: {
    padding: '25px 25px 25px 25px',
    border: 'none',
    width: '25%',
    backgroundColor: 'white',
  },
});

const TopBar = ({ onClick }) => {
  const classes = useStyles();
  return (
    <button className={classes.root} onClick={onClick}>
      <img src={'/assets/back.png'} alt={'back'} />{' '}
    </button>
  );
};

export default TopBar;
