import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    stroke: '#b68647',
    marginRight: '10px',
  },
});
const ModelLogo = () => {
  const classes = useStyles();
  return (
    <svg
      className={classes.root}
      xmlns="http://www.w3.org/2000/svg"
      width="23.414"
      height="24.207"
      viewBox="0 0 23.414 24.207"
    >
      <g transform="translate(-0.293)">
        <path
          d="M12,6,4,9.5,12,13l8-3.5Z"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M7.059,20.338,12,22.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M4,9.5V17"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M12,13v9.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M10,2.5l2-2,2,2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M1,20.5v3H4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M1,23.5,5.5,19"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M16.941,20.338,12,22.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M20,9.5V17"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M23,20.5v3H20"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M23,23.5,18.5,19"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M12,.5V4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default ModelLogo;
