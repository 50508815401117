import { Fragment, useEffect, useRef, useState } from 'react';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import Controls from './Controls';
import ARLogo from './ar.svg';

const useStyles = createUseStyles({
  root: {
    // backgroundColor: '#231f20',
    fontFamily: 'TTCommonsTR',
    padding: '5px 25px 25px 25px',
    height: 'calc(100% - 34px - 68px)',
  },
  grid: {
    color: '#b68647',
    display: 'grid',
    placeItems: 'center',
    height: '100vh',
  },
  button: {
    color: '#b68647',
    padding: '18px 21px',
    width: 'auto',
    border: '1px solid #b68647',
    cursor: 'pointer',
  },
  labelContainer: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '25px',
    paddingTop: '50px',
  },
  description: {
    fontFamily: 'TTCommonsTR',
  },
  isPresenting: {
    display: 'block',
  },
  labelInAr: {
    fontFamily: 'TTCommonsTRLight',
    fontWeight: 300,
    color: 'white',
    fontSize: '21px',
    lineHeight: 1.2,
    letterSpacing: '1pt',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontFamily: 'TTCommonsTRBold',
    fontWeight: 300,
    color: '#44464b',
    fontSize: '21px',
    lineHeight: 1.2,
    letterSpacing: '1pt',
  },
  subLabel: {
    fontFamily: 'TTCommonsTRLight',
    fontWeight: 300,
    color: '#44464b',
    // fontSize: '21px',
    lineHeight: 1.2,
    letterSpacing: '1pt',
    paddingBottom: '5px',
  },
  modelViewer: {
    minHeight: '50vh',
    // backgroundColor: '#eee',
    // '--poster-color': '#eee',
    width: '100%',
    '@media (min-width: 1024px)': {
      minHeight: '50vh',
    },
    // height: '100vh',
  },
  imgContainer: {},
  img: {
    width: '75%',
    margin: 'auto',
    display: 'block',
  },
  arButton: {
    display: 'none',
  },
  customArButton: {
    display: 'flex',
    justifyContent: 'space-around',
    color: 'white',
    backgroundColor: 'black',
    padding: '16px 0px 10px 0px',
    textAlign: 'center',
    borderRadius: 4,
    width: '100%',
  },
});

const colors = [
  {
    name: 'Bronze',
    colorString: '0.149, 0.137, 0.208, 1.0',
    src: 'COATED_ALU_BR.jpg',
  },
  {
    name: 'Mushroom',
    colorString: '0.753, 0.741, 0.675, 1.0',
    src: 'COATED_ALU_MR.jpg',
  },
  {
    name: 'Olive Green',
    colorString: '0.553, 0.616, 0.576, 1.0',
    src: 'COATED_ALU_OL.jpg',
  },
  {
    name: 'Sky Blue',
    colorString: '0.557, 0.678, 0.749, 1.0',
    src: 'COATED_ALU_SB.jpg',
  },
];

const DetailPage = ({
  src,
  iosSrc,
  title,
  subTitle,
  poster,
  oldPrice,
  newPrice,
  setPage,
  description,
  heart,
  toggleHeart,
}) => {
  const modelRef = useRef(null);
  const classes = useStyles();
  const [color, setColor] = useState('Bronze');
  const [isPresenting, setIsPresenting] = useState(false);
  const [isPlaced, setIsPlaced] = useState(false);
  console.log('color', color);
  const activeColor = colors.find(({ name }) => name === color);

  console.log('active', activeColor.colorString);
  useEffect(() => {
    console.log('effect', activeColor.colorString);
    if (activeColor.colorString && modelRef.current && modelRef.current.model) {
      const modelViewer = modelRef.current;
      console.log('modelViewer', modelViewer.model);

      const color = activeColor.colorString
        .split(',')
        .map((numberString) => parseFloat(numberString));

      console.log('Changing color to: ', color);
      const { materials } = modelViewer.model;

      materials.forEach((material) => {
        // console.log('material', material);
        material.pbrMetallicRoughness.setBaseColorFactor(color);
      });
    }
  }, [activeColor.colorString, color]);

  useEffect(() => {
    console.log('modelRef', modelRef.current);
    // document
    //   .querySelector('#model-viewer')
    modelRef.current.addEventListener('ar-status', (event) => {
      console.log('event', event.detail.status);
      console.log('button', document.querySelector('.exit-webxr-ar-button'));
      if (event.detail.status === 'failed') {
        const error = document.querySelector('#error');
        error.classList.remove('hide');
        error.addEventListener('transitionend', (event) => {
          error.classList.add('hide');
        });
      }

      if (event.detail.status === 'session-started') {
        setIsPresenting(true);
      }
      if (event.detail.status === 'not-presenting') {
        setIsPresenting(false);
        setIsPlaced(false);
      }
      if (event.detail.status === 'object-placed') {
        setIsPlaced(true);
      }
    });
  });

  return (
    <Fragment>
      <TopBar onClick={() => setPage('livingRoom')} />

      <div className={classes.root}>
        <div className={classes.titleRow}>
          <div className={classes.label}>{title}</div>
          {subTitle && <div className={classes.label}>{subTitle}</div>}
          <img
            onClick={toggleHeart}
            src={`/assets/${heart ? 'heartfilled' : 'heart'}.svg`}
            alt={'heart'}
          />
        </div>

        <model-viewer
          ref={modelRef}
          src={src}
          ios-src={iosSrc}
          poster={poster}
          shadow-intensity='1'
          ar
          ar-modes='webxr scene-viewer quick-look'
          ar-scale='fixed'
          camera-controls
          alt='A 3D model'
          class={classes.modelViewer}
          camera-orbit={'-0.758802552117009rad 1.235671094289293rad auto'}
        >
          <div
            className={clsx(
              classes.labelContainer,
              isPresenting && classes.isPresenting
            )}
          >
            <h1 className={classes.labelInAr}>{title}</h1>
          </div>
          <button className={classes.arButton} slot='ar-button' id='ar-button'>
            View in your space
          </button>
        </model-viewer>
        <div style={{ display: 'flex' }}>
          <div style={{ textDecoration: 'line-through', marginRight: '10px' }}>
            {oldPrice}
          </div>
          <div>{newPrice}</div>
        </div>
        <div className={classes.description}>{description}</div>
        <div style={{ marginTop: '20px' }} className={classes.subLabel}>
          <b>Color</b>
        </div>
        <Controls colors={colors} setColor={({ name }) => setColor(name)} />
        <button
          onClick={() => {
            document.getElementById('ar-button').click();
          }}
          className={classes.customArButton}
          id='custom-ar-button'
        >
          <div>See AR preview</div>
          <img src={ARLogo} alt='AR Logo' />
        </button>
      </div>
    </Fragment>
  );
};
// <img className={classes.img} src={poster} alt={'poster'} />

export default DetailPage;
