import { createUseStyles } from 'react-jss';
import TopBar from './TopBar';

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  title: {
    fontSize: '21px',
    fontFamily: 'TTCommonsTRBold',
    alignSelf: 'baseline',
    padding: '18px 0',
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#F5ECE8',
  },
  productContainer: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows:
      '3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3% 3%',
    rowGap: '2%',
    gridAutoFlow: 'row',
    alignItems: 'center',
    margin: '5px 5% 40px',
    height: 'calc(100% - 110px)',
    padding: '10px 0',
  },
  label: {
    margin: '0px 5px',
  },
  imageContainer: {
    width: '95%',
    height: '100%',
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    flexGrow: 1,
    border: '1px solid #E4E4E4',
    borderRadius: '4px',
    '& img': {
      width: '70%',
      height: '69%',
    },
    '& div:first-of-type': {
      marginTop: 10,
      fontWeight: 'bold',
    },
  },
});

const LivingRoom = ({ setPage, setActive }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar onClick={() => setPage('productList')} />
      <div className={classes.title}>Living Room</div>
      <div className={classes.productContainer}>
        <div
          style={{
            gridArea: '1 / 1 / span 6 / auto',
            alignSelf: 'start',
          }}
          className={classes.imageContainer}
          onClick={() => setActive('N101')}
        >
          <img
            style={{ width: '80%', height: '95%' }}
            src='/assets/livingroom/N101sofa.png'
            alt='N101 sofa - 3 seater'
          />
          <div
            style={{
              transform: 'translate(0px, -135%)',
            }}
            className={classes.label}
          >
            N101 sofa - 3 seater
          </div>
        </div>
        <div
          style={{
            gridArea: '1 / 2 / span 8 / auto',
            alignSelf: 'start',
          }}
          className={classes.imageContainer}
          onClick={() => setActive('N101')}
        >
          <img src='/assets/livingroom/teakTabwa.png' alt='Teak Tabwa' />
          <div className={classes.label}>Teak Tabwa storage cupboar</div>
        </div>
        <div
          style={{
            gridArea: '7 / 1 / span 9 / auto',
          }}
          className={classes.imageContainer}
          onClick={() => setActive('N101')}
        >
          <img
            src='/assets/livingroom/teakStudio.png'
            alt='Teak Studio'
            style={{ height: '78%' }}
          />
          <div>Teak Studio Rack</div>
        </div>
        <div
          style={{ gridArea: '9 / 2 / span 5 / auto' }}
          className={classes.imageContainer}
          onClick={() => setActive('CIRCLETABLE')}
        >
          <img
            src='/assets/livingroom/circleTable.png'
            alt='Oak Circle dining table'
            style={{ width: '65%', height: '62%' }}
          />
          <div style={{ margin: '0px' }}>Oak Circle dining table</div>
        </div>
        <div
          style={{
            gridArea: '16 / 1 / span 5 / auto',
            alignSelf: 'end',
          }}
          className={classes.imageContainer}
          onClick={() => setActive('CIRCLETABLE')}
        >
          <img
            src='/assets/livingroom/N701footStool.png'
            alt='N701 foot stool'
          />
          <div style={{ margin: '0px' }}>N701 sofa - footstool</div>
        </div>
        <div
          style={{
            gridArea: '14 / 2 / span 7 / auto',
            alignSelf: 'end',
          }}
          className={classes.imageContainer}
          onClick={() => setActive('CIRCLETABLE')}
        >
          <img
            src='/assets/livingroom/anderStorage.png'
            alt='Ander Storage Cubboard'
          />
          <div>Anders storage cupboard</div>
        </div>
      </div>
    </div>
  );
};

export default LivingRoom;
