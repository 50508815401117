/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    margin: '10px 0 10px 0',
    padding: '0',
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'center',
    width: '100%',
  },
  item: {
    overflow: 'visible',
    position: 'relative',
    display: 'inline-block',
    margin: '0 4px',
    padding: '0',
  },
  optionWrapper: {
    overflow: 'visible',
  },
  label: {
    position: 'relative',
    width: '25px',
    marginBottom: '0',
    display: 'inline-block',
  },
  input: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
    boxSizing: 'border-box',
    padding: 0,
    overflow: 'visible',
  },
  texture: {
    cursor: 'pointer',
    backgroundColor: '#f8f8f7',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    borderRadius: '30px',
    border: '1px solid #dee2e6',
    position: 'relative',
    zIndex: 0,
    width: '25px',
    height: '25px',
  },
});

const Controls = ({ setColor, colors }) => {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      {colors.map(({ name, src, color }) => (
        <li className={classes.item} data-group="pa_frame">
          <div className={classes.optionWrapper}>
            <label className={classes.label}>
              <input
                className={classes.input}
                type="radio"
                name="pa_frame"
                value={color}
              />
              <div
                className={classes.texture}
                style={{ backgroundImage: `url(/assets/color/${src})` }}
                onClick={() => setColor({ name, colorString: color })}
              />
            </label>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Controls;
